
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

@Component({
	components: { VueJsonPretty },
})
export default class InvalidSchema extends Vue {
	@Prop({ type: Object, required: true }) private jsonObject!: Object;
	@Prop({ type: Array, required: true }) private jsonObjectErrors!: [];

	activeTab: number | null = null;
}
