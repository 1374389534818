var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("data-model-header", { attrs: { tableItems: _vm.tableItems } }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _vm.isLoading
            ? _c("v-progress-linear", { attrs: { indeterminate: true } })
            : [
                _vm.isJsonValid
                  ? _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "v-tabs",
                              {
                                attrs: {
                                  color: "black",
                                  "background-color": "#E0E0E0",
                                  "slider-color": "primary",
                                },
                                model: {
                                  value: _vm.activeTab,
                                  callback: function ($$v) {
                                    _vm.activeTab = $$v
                                  },
                                  expression: "activeTab",
                                },
                              },
                              [
                                _c(
                                  "v-tab",
                                  { attrs: { ripple: "", href: "#overview" } },
                                  [_vm._v("Overview")]
                                ),
                                _c(
                                  "v-tab",
                                  { attrs: { ripple: "", href: "#data" } },
                                  [_vm._v("Data")]
                                ),
                                _c(
                                  "v-tab",
                                  { attrs: { ripple: "", href: "#schema" } },
                                  [_vm._v("Schema")]
                                ),
                                _c(
                                  "v-tab",
                                  { attrs: { ripple: "", href: "#workflow" } },
                                  [_vm._v("Workflow")]
                                ),
                                _c(
                                  "v-tab",
                                  {
                                    attrs: {
                                      ripple: "",
                                      href: "#documentation",
                                    },
                                  },
                                  [_vm._v("Documentation")]
                                ),
                                _c(
                                  "v-tab",
                                  { attrs: { ripple: "", href: "#fulljson" } },
                                  [_vm._v("Full Json")]
                                ),
                                _c(
                                  "v-tab",
                                  { attrs: { ripple: "", href: "#notes" } },
                                  [_vm._v("Notes")]
                                ),
                                _c(
                                  "v-tab-item",
                                  { attrs: { value: "overview" } },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-h6" },
                                              [_vm._v("Overview")]
                                            ),
                                            _c("v-spacer"),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-card",
                                              { attrs: { elevation: "0" } },
                                              [
                                                _c(
                                                  "v-card-title",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        staticClass: "mr-3",
                                                        attrs: {
                                                          color:
                                                            "blue-grey lighten-3",
                                                          large: "",
                                                        },
                                                      },
                                                      [_vm._v("table_chart")]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-h4 font-weight-bold primary--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            this
                                                              .dataTableDetails
                                                              .id
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c("v-spacer"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "card-title-right",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "span",
                                                                        _vm._g(
                                                                          {},
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " Last Update: " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .refreshedTimestamp
                                                                                  .dateFromNow
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              1057009910
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .refreshedTimestamp
                                                                    .dateFormatted
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            on: {
                                                              click:
                                                                _vm.getDataTableDetails,
                                                            },
                                                          },
                                                          [_vm._v("Refresh")]
                                                        ),
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            attrs: {
                                                              color: "primary",
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.queryInBigQuery,
                                                            },
                                                          },
                                                          [_vm._v("Query")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-card-text",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          [
                                                            _c("v-textarea", {
                                                              attrs: {
                                                                name: "Description",
                                                                label:
                                                                  "Description",
                                                                value:
                                                                  _vm
                                                                    .dataTableDetails
                                                                    .description,
                                                                "auto-grow": "",
                                                                rows: "1",
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "text-h6",
                                                              attrs: {
                                                                label:
                                                                  "Account",
                                                                value:
                                                                  _vm
                                                                    .dataTableDetails
                                                                    .account,
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "text-h6",
                                                              attrs: {
                                                                label:
                                                                  "Project Id",
                                                                value:
                                                                  this
                                                                    .projectId,
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "text-h6",
                                                              attrs: {
                                                                label:
                                                                  "Dataset",
                                                                value:
                                                                  this
                                                                    .datasetId,
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "text-h6",
                                                              attrs: {
                                                                label:
                                                                  "Location",
                                                                value:
                                                                  _vm
                                                                    .dataTableDetails
                                                                    .location,
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-row",
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "text-h6",
                                                              attrs: {
                                                                label:
                                                                  "Row Number",
                                                                value:
                                                                  _vm.numRowsFormatted,
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "text-h6",
                                                              attrs: {
                                                                label:
                                                                  "Table Size",
                                                                value:
                                                                  _vm.numBytesFormatted,
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "text-h6",
                                                              attrs: {
                                                                label:
                                                                  "Table Type",
                                                                value:
                                                                  _vm
                                                                    .dataTableDetails
                                                                    .kind,
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "3",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              staticClass:
                                                                "text-h6",
                                                              attrs: {
                                                                label:
                                                                  "Time Partitioned",
                                                                value:
                                                                  _vm.isTimePartitioned,
                                                                readonly: "",
                                                                outline: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.dataTableDetails
                                                      .update_infos
                                                      ? _c(
                                                          "v-row",
                                                          [
                                                            _c(
                                                              "v-col",
                                                              {
                                                                attrs: {
                                                                  cols: "3",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-text-field",
                                                                  {
                                                                    staticClass:
                                                                      "text-h6",
                                                                    attrs: {
                                                                      label:
                                                                        "Temporary table",
                                                                      value:
                                                                        _vm
                                                                          .dataTableDetails
                                                                          .temporary_table,
                                                                      readonly:
                                                                        "",
                                                                      outline:
                                                                        "",
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  { attrs: { value: "data" } },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-h6" },
                                              [_vm._v("Data Overview")]
                                            ),
                                            _c("v-spacer"),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c("vue-good-table", {
                                              attrs: {
                                                columns: this.columns,
                                                rows: this.rows,
                                                styleClass:
                                                  "vgt-table condensed striped",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "table-row",
                                                    fn: function (props) {
                                                      return [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                props
                                                                  .formattedRow[
                                                                  props.column
                                                                    .field
                                                                ]
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2545751082
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  { attrs: { value: "schema" } },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c("tableSchemaView", {
                                          attrs: {
                                            schemaRows:
                                              _vm.dataTableDetails.schema
                                                .fields,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  { attrs: { value: "workflow" } },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-h6 mt-2 ml-1",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.dataTableDetails.dag_id
                                                  )
                                                ),
                                              ]
                                            ),
                                            _c("v-spacer"),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c("v-textarea", {
                                                      attrs: {
                                                        name: "Description",
                                                        label: "Description",
                                                        value:
                                                          _vm.dataTableDetails
                                                            .short_description,
                                                        "auto-grow": "",
                                                        rows: "1",
                                                        readonly: "",
                                                        outline: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 text-caption",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Configuration Id"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: _vm.getConfigurationRouteName(
                                                              _vm.getFormattedDagTypeValue(
                                                                _vm
                                                                  .dataTableDetails
                                                                  .dag_type
                                                              )
                                                            ),
                                                            params: {
                                                              id: _vm
                                                                .dataTableDetails
                                                                .dag_id,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .dataTableDetails
                                                                .dag_id
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "text-subtitle-1",
                                                      attrs: {
                                                        label:
                                                          "Configuration Type",
                                                        value:
                                                          _vm.getFormattedDagTypeValue(
                                                            _vm.dataTableDetails
                                                              .dag_type
                                                          ),
                                                        readonly: "",
                                                        outline: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "mb-0 text-caption",
                                                      },
                                                      [_vm._v("Run Id")]
                                                    ),
                                                    _c(
                                                      "router-link",
                                                      {
                                                        attrs: {
                                                          to: {
                                                            name: _vm.getRunRouteName(
                                                              _vm.getFormattedDagTypeValue(
                                                                _vm
                                                                  .dataTableDetails
                                                                  .dag_type
                                                              )
                                                            ),
                                                            params: {
                                                              id:
                                                                _vm.getFormattedDagTypeValue(
                                                                  _vm
                                                                    .dataTableDetails
                                                                    .dag_type
                                                                ) ===
                                                                "tables-to-tables"
                                                                  ? `${_vm.dataTableDetails.dag_id}_${_vm.dataTableDetails.dag_run_id}`
                                                                  : _vm
                                                                      .dataTableDetails
                                                                      .dag_run_id,
                                                            },
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm
                                                                .dataTableDetails
                                                                .dag_run_id
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "v-col",
                                                  { attrs: { cols: "3" } },
                                                  [
                                                    _c("v-text-field", {
                                                      staticClass:
                                                        "text-subtitle-1",
                                                      attrs: {
                                                        label: "Last Modified",
                                                        value:
                                                          this
                                                            .workflowLastModifiedTime,
                                                        readonly: "",
                                                        outline: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.dataTableDetails.update_infos
                                              ? [
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "text-subtitle-1",
                                                            attrs: {
                                                              label:
                                                                "Total Update Tasks",
                                                              value:
                                                                _vm
                                                                  .dataTableDetails
                                                                  .update_infos
                                                                  .length,
                                                              readonly: "",
                                                              outline: "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "text-subtitle-1",
                                                            attrs: {
                                                              label:
                                                                "Total Update Duration",
                                                              value:
                                                                _vm.updateInfosJobsDuration,
                                                              readonly: "",
                                                              outline: "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "text-subtitle-1",
                                                            attrs: {
                                                              label:
                                                                "Total Bytes Processed",
                                                              value:
                                                                _vm.sumUpdateInfosProperty(
                                                                  "total_bytes_processed"
                                                                ),
                                                              readonly: "",
                                                              outline: "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "text-subtitle-1",
                                                            attrs: {
                                                              label:
                                                                "Total Bytes Billed",
                                                              value:
                                                                _vm.sumUpdateInfosProperty(
                                                                  "total_bytes_billed"
                                                                ),
                                                              readonly: "",
                                                              outline: "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass:
                                                              "text-subtitle-1",
                                                            attrs: {
                                                              label:
                                                                "Total Slot Millis",
                                                              value: `${_vm.sumUpdateInfosProperty(
                                                                "slot_millis"
                                                              )}ms`,
                                                              readonly: "",
                                                              outline: "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "h2",
                                                    {
                                                      staticClass:
                                                        "black--text mt-5 mb-3",
                                                    },
                                                    [_vm._v("Update Tasks")]
                                                  ),
                                                  _c("v-data-table", {
                                                    attrs: {
                                                      headers:
                                                        _vm.updateTaskTable
                                                          .headers,
                                                      items:
                                                        _vm.updateTaskTable
                                                          .items,
                                                      "hide-default-footer": "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "item.total_source_table",
                                                          fn: function ({
                                                            item,
                                                          }) {
                                                            return [
                                                              _c("pre", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item
                                                                      .referenced_tables
                                                                      .length
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      4146455473
                                                    ),
                                                  }),
                                                  _c(
                                                    "h2",
                                                    {
                                                      staticClass:
                                                        "black--text mt-10 mb-3",
                                                    },
                                                    [_vm._v("Source Tables")]
                                                  ),
                                                  _c("v-data-table", {
                                                    attrs: {
                                                      headers:
                                                        _vm.sourceTablesTable
                                                          .headers,
                                                      items:
                                                        _vm.sourceTablesTable
                                                          .items,
                                                      "hide-default-footer": "",
                                                    },
                                                  }),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  { attrs: { value: "documentation" } },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-text",
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  {
                                                    staticClass: "ml-3",
                                                    attrs: {
                                                      cols: "10",
                                                      offset: "0",
                                                    },
                                                  },
                                                  [
                                                    _c("vue-markdown", {
                                                      attrs: {
                                                        source:
                                                          _vm.dataTableDetails
                                                            .doc_md,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  { attrs: { value: "fulljson" } },
                                  [
                                    _c(
                                      "v-card",
                                      [
                                        _c(
                                          "v-card-title",
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-h6" },
                                              [_vm._v("Full Json")]
                                            ),
                                            _c("v-spacer"),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-card-text",
                                          [
                                            _c("vue-json-pretty", {
                                              attrs: {
                                                data: this.dataTableDetails,
                                                deep: 5,
                                                "show-double-quotes": true,
                                                "show-length": true,
                                                "show-line": false,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-tab-item",
                                  { attrs: { value: "notes" } },
                                  [
                                    _c("notes-tab", {
                                      attrs: {
                                        "module-name": "dataTableDetails",
                                        "related-doc-id": `${_vm.projectId}-${_vm.datasetId}-${_vm.tableId}`,
                                        account: _vm.dataTableDetails.account,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _c("JsonSchemaIsInvalid", {
                      attrs: {
                        jsonObject: this.dataTableDetails,
                        jsonObjectErrors: this.jsonObjectErrors,
                      },
                    }),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }