
// TODO: Refactor view

import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';
import { AnyObject } from '@/types';
import store from '@/store';
import JsonSchemaIsInvalid from '@/components/data-models/InvalidSchema.vue';
import DataModelHeader from '@/components/data-models/DataModelHeader.vue';
import tableSchemaView from '@/components/data-operations/common/item/schema/TableSchemaView.vue';
import NotesTab from '@/components/data-operations/common/item/notes/NotesTab.vue';
// library to validate Object Json Schema from Firestore
import Ajv, { ErrorObject } from 'ajv';
// library to convert units (bite to Go ) and format number
import VueMarkdown from 'vue-markdown';

import {
	CONTEXT_CONFIGURATIONS_ITEM,
	DATA_TABLE_DETAILS,
	STORAGE_TO_STORAGE_CONFIGURATIONS_ITEM,
	STORAGE_TO_STORAGE_RUNS_ITEM,
	STORAGE_TO_TABLES_CONFIGURATIONS_ITEM,
	STORAGE_TO_TABLES_RUNS_ITEM,
	TABLE_TO_STORAGE_CONFIGURATIONS_ITEM,
	TABLE_TO_STORAGE_RUNS_ITEM,
	TABLES_LISTING,
	TABLES_TO_TABLES_CONFIGURATIONS_ITEM,
	TABLES_TO_TABLES_RUNS_ITEM,
	VM_LAUNCHER_CONFIGURATIONS_ITEM,
	VM_LAUNCHER_RUNS_ITEM,
	WORKFLOW_CONFIGURATIONS_ITEM,
	WORKFLOW_STATUS_ITEM,
	XML_CONVERSION_CONFIGURATIONS_ITEM,
} from '@/constants/router/routes-names';
import { getBigQueryURL, getFormattedNumBytes, getFormattedNumRows } from '@/util/data-models';

import 'vue-good-table/dist/vue-good-table.css';

interface Column {
	label: string;
	field: string;
}

@Component({
	components: {
		VueJsonPretty,
		tableSchemaView,
		JsonSchemaIsInvalid,
		DataModelHeader,
		'vue-good-table': require('vue-good-table').VueGoodTable,
		VueMarkdown,
		NotesTab,
	},
	computed: {
		...mapState({
			dataTableDetails: (state: any) => state.dataTableDetails.data,
			schemas: (state: any) => state.schemas.data,
		}),
	},
})
export default class DataTableDetails extends Vue {
	private dataTableDetails: any; // TODO: Type
	private schemas: any; // TODO: Type

	isLoading: boolean = true;
	columns: Column[] = [];
	rows: never[] = [];
	schemaRows: never[] = [];
	activeTab: null = null;
	isJsonValid: boolean | PromiseLike<any> = false;
	jsonObjectErrors: ErrorObject[] | null | undefined = null;

	async activated() {
		await this.getDataTableDetails();
	}

	async getDataTableDetails() {
		this.isLoading = true;
		await this.getFirestoreData();

		const ajv = new Ajv({ allErrors: true });
		const schema = JSON.parse(this.schemas['gbq-table-preview-table'].schema);
		const testJson = ajv.compile(schema);

		this.isJsonValid = testJson(this.dataTableDetails);
		this.jsonObjectErrors = testJson.errors;

		if (this.isJsonValid) {
			this.rows = Object.values(this.dataTableDetails.json);
			const dataTableOverviewColumnsKey = Object.keys(this.rows[0]);
			this.columns = dataTableOverviewColumnsKey.map((data: string) => ({ label: data, field: data }));
			this.schemaRows = Object.values(this.dataTableDetails.schema.fields);
		}

		this.isLoading = false;
	}

	async getFirestoreData() {
		await store.dispatch('dataTableDetails/closeDBChannel', {
			clearModule: true,
		});
		await store.dispatch('dataTableDetails/fetchAndAdd', {
			projectId: this.projectId,
			datasetId: this.datasetId,
			tableId: this.tableId,
			limit: 0,
		});
	}

	queryInBigQuery() {
		window.open(getBigQueryURL(this.projectId, this.datasetId, this.tableId), '_blank');
	}

	// TODO: Make common with "src/components/data-operations/activities-overview/runs/CollectionListing.vue"
	getConfigurationRouteName(type: string) {
		switch (type) {
			case 'storage-to-storage':
				return STORAGE_TO_STORAGE_CONFIGURATIONS_ITEM;
			case 'workflow':
				return WORKFLOW_CONFIGURATIONS_ITEM;
			case 'vm-launcher':
				return VM_LAUNCHER_CONFIGURATIONS_ITEM;
			case 'storage-to-tables':
				return STORAGE_TO_TABLES_CONFIGURATIONS_ITEM;
			case 'tables-to-tables':
				return TABLES_TO_TABLES_CONFIGURATIONS_ITEM;
			case 'table-to-storage':
				return TABLE_TO_STORAGE_CONFIGURATIONS_ITEM;
			case 'context':
				return CONTEXT_CONFIGURATIONS_ITEM;
			case 'xml-conversion':
				return XML_CONVERSION_CONFIGURATIONS_ITEM;
			case 'api-to-storage':
				return ''; // TODO: Set route
			default:
				return '';
		}
	}

	getFormattedDagTypeValue(dagType: string) {
		return dagType === 'gbq-to-gbq' ? 'tables-to-tables' : dagType; // TODO: remove when it will be changed in data
	}

	// TODO: Make common with "src/components/data-operations/activities-overview/runs/CollectionListing.vue"
	getRunRouteName(type: string) {
		switch (type) {
			case 'storage-to-storage':
				return STORAGE_TO_STORAGE_RUNS_ITEM;
			case 'workflow':
				return WORKFLOW_STATUS_ITEM;
			case 'vm-launcher':
				return VM_LAUNCHER_RUNS_ITEM;
			case 'storage-to-tables':
				return STORAGE_TO_TABLES_RUNS_ITEM;
			case 'tables-to-tables':
				return TABLES_TO_TABLES_RUNS_ITEM;
			case 'table-to-storage':
				return TABLE_TO_STORAGE_RUNS_ITEM;
			case 'context':
				return '';
			case 'xml-conversion':
				return '';
			case 'api-to-storage':
				return '';
			default:
				return '';
		}
	}

	sumUpdateInfosProperty(key: string) {
		return this.dataTableDetails.update_infos.reduce((a: number, b: AnyObject) => a + b[key], 0);
	}

	get tableId() {
		return this.$route.params.tableId;
	}

	get datasetId() {
		return this.$route.params.datasetId;
	}

	get projectId() {
		return this.$route.params.projectId;
	}

	get numBytesFormatted() {
		return getFormattedNumBytes(Number(this.dataTableDetails.numBytes));
	}

	get numRowsFormatted() {
		return getFormattedNumRows(Number(this.dataTableDetails.numRows));
	}

	get isTimePartitioned() {
		return this.dataTableDetails.timePartitioning !== undefined;
	}

	get refreshedTimestamp() {
		const dateFormatted = moment(this.dataTableDetails.refreshed_timestamp).format('YYYY/MM/DD - HH:mm');
		const dateFromNow = moment(this.dataTableDetails.refreshed_timestamp).fromNow();
		return {
			dateFormatted: dateFormatted,
			dateFromNow: dateFromNow,
		};
	}

	get workflowLastModifiedTime() {
		return moment(this.dataTableDetails.lastModifiedTime, 'x').format('YYYY/MM/DD - HH:mm');
	}

	get tableItems() {
		return [
			{
				text: this.projectId,
			},
			{
				text: this.datasetId,
				to: {
					name: TABLES_LISTING,
					params: { projectId: this.projectId, datasetId: this.datasetId },
				},
			},
			{
				text: this.tableId,
				to: {
					name: DATA_TABLE_DETAILS,
					params: { projectId: this.projectId, datasetId: this.datasetId },
				},
			},
		];
	}

	get updateInfosJobsDuration() {
		const accumulatedJobsDurations = this.dataTableDetails.update_infos.reduce(
			(a: number, b: AnyObject) => a + this.$moment.duration(b.job_duration).asSeconds(),
			0
		);
		return `${accumulatedJobsDurations}s`;
	}

	get updateTaskTable() {
		const headers = [
			{ text: 'Order', value: 'task_order' },
			{ text: 'Task', value: 'task_id' },
			{ text: 'Type', value: 'task_type' },
			{ text: 'Script SQL', value: 'is_gbq_script' },
			{ text: 'Update Duration', value: 'job_duration' },
			{ text: 'Bytes Processed', value: 'total_bytes_processed' },
			{ text: 'Bytes Billed', value: 'total_bytes_billed' },
			{ text: 'Slot Millis', value: 'slot_millis' },
			{ text: 'Total Source Tables', value: 'total_source_table' },
		];
		const items = this.dataTableDetails.update_infos;
		return { headers, items };
	}

	get sourceTablesTable() {
		const headers = [
			{ text: 'Order', value: 'task_order' },
			{ text: 'Task', value: 'task_id' },
			{ text: 'Project ID', value: 'project_id' },
			{ text: 'Dataset', value: 'dataset_id' },
			{ text: 'Table', value: 'table_id' },
		];
		const items = this.dataTableDetails.update_infos
			.map((updateInfo: AnyObject) => {
				const infos = [];
				for (const referencedTable of updateInfo.referenced_tables) {
					infos.push({
						task_order: updateInfo.task_order,
						task_id: updateInfo.task_id,
						project_id: referencedTable.project_id,
						dataset_id: referencedTable.dataset_id,
						table_id: referencedTable.table_id,
					});
				}
				return infos;
			})
			.flat();
		return { headers, items };
	}
}
