var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("data-model-header", {
        attrs: { title: "Tables Listing", "table-items": _vm.tableItems },
      }),
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "transparent", elevation: "0" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: "Search",
                  "single-line": "",
                  "hide-details": "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-spacer"),
              _c("v-checkbox", {
                attrs: { label: "Time partitioning", "hide-details": "" },
                model: {
                  value: _vm.filterOnTimePartitioning,
                  callback: function ($$v) {
                    _vm.filterOnTimePartitioning = $$v
                  },
                  expression: "filterOnTimePartitioning",
                },
              }),
              _vm.isLoading
                ? _c("v-progress-circular", {
                    staticClass: "ml-3",
                    attrs: { indeterminate: "", size: "20", color: "primary" },
                  })
                : _c(
                    "v-icon",
                    { attrs: { right: "" }, on: { click: _vm.fetchTables } },
                    [_vm._v("refresh")]
                  ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass: "elevation-1 mb-4",
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              loading: _vm.isLoading,
              search: _vm.search,
              "items-per-page": Number.POSITIVE_INFINITY,
              "hide-default-footer": "",
            },
            scopedSlots: _vm._u([
              {
                key: "item.table_id",
                fn: function ({ item }) {
                  return [
                    _c(
                      "router-link",
                      {
                        class: { "red--text": !item.table_id },
                        attrs: { to: _vm.getTableViewLink(item.id) },
                      },
                      [
                        _c("p", { staticClass: "text-subtitle-1" }, [
                          _vm._v(_vm._s(_vm.getTableName(item.table_id))),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.temporary_table",
                fn: function ({ item }) {
                  return [
                    item.temporary_table
                      ? _c("v-icon", { attrs: { color: "success" } }, [
                          _vm._v("mdi-recycle"),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "item.numRows",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-progress-linear",
                      {
                        staticStyle: { width: "100px" },
                        attrs: {
                          value: _vm.getPropertyPercent(
                            "numRows",
                            item.numRows
                          ),
                          height: "25",
                        },
                      },
                      [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.getNumRowsFormatted(item.numRows))),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.numBytes",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-progress-linear",
                      {
                        staticStyle: { width: "100px" },
                        attrs: {
                          value: _vm.getPropertyPercent(
                            "numBytes",
                            item.numBytes
                          ),
                          height: "25",
                        },
                      },
                      [
                        _c("strong", [
                          _vm._v(
                            _vm._s(_vm.getNumBytesFormatted(item.numBytes))
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.time_partitioned",
                fn: function ({ item }) {
                  return [
                    _c("v-switch", {
                      attrs: { readonly: "" },
                      model: {
                        value: item.timePartitioning,
                        callback: function ($$v) {
                          _vm.$set(item, "timePartitioning", $$v)
                        },
                        expression: "item.timePartitioning",
                      },
                    }),
                  ]
                },
              },
              {
                key: "item.refreshed_timestamp",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-chip",
                                    _vm._g(
                                      {
                                        attrs: {
                                          color: _vm.getRefreshedTimestampColor(
                                            item.refreshed_timestamp
                                          ),
                                          "text-color": "white",
                                          small: "",
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm._f("moment")(
                                              item.refreshed_timestamp,
                                              "from"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("moment")(
                                item.refreshed_timestamp,
                                "YYYY/MM/DD - HH:mm:ss"
                              )
                            )
                          ),
                        ]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "item.query",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { small: "", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.openInBigQuery(item.id)
                          },
                        },
                      },
                      [_vm._v("Query")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }