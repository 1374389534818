var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", offset: "0" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("span", { staticClass: "text-h4 font-weight-bold" }, [
                        _vm._v(_vm._s(this.jsonObject.id)),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-chip",
                        { attrs: { color: "red", "text-color": "white" } },
                        [
                          _vm._v(
                            _vm._s(this.jsonObjectErrors.length + " errors")
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", offset: "0" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        color: "grey lighten-3",
                        "slider-color": "primary",
                      },
                      model: {
                        value: _vm.activeTab,
                        callback: function ($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab",
                      },
                    },
                    [
                      _c(
                        "v-tab",
                        { attrs: { ripple: "", href: "#jsonobject" } },
                        [_vm._v("Json Object")]
                      ),
                      _c(
                        "v-tab",
                        { attrs: { ripple: "", href: "#jsonobjecterrors" } },
                        [_vm._v("Json Errors")]
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "jsonobject" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("span", { staticClass: "text-h6" }, [
                                    _vm._v(_vm._s(this.jsonObject.id)),
                                  ]),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c("vue-json-pretty", {
                                    attrs: {
                                      data: this.jsonObject,
                                      deep: 5,
                                      "show-double-quotes": true,
                                      "show-length": true,
                                      "show-line": false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-tab-item",
                        { attrs: { value: "jsonobjecterrors" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-title",
                                [
                                  _c("span", { staticClass: "text-h6" }, [
                                    _vm._v(" Json Schema Errors"),
                                  ]),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                              _c(
                                "v-card-text",
                                [
                                  _c("vue-json-pretty", {
                                    attrs: {
                                      data: this.jsonObjectErrors,
                                      deep: 5,
                                      "show-double-quotes": true,
                                      "show-length": true,
                                      "show-line": false,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }