var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-toolbar",
    {
      attrs: { dense: "", tabs: "", color: "white elevation-0" },
      scopedSlots: _vm._u([
        {
          key: "extension",
          fn: function () {
            return [
              _c("v-breadcrumbs", {
                attrs: { items: _vm.tableItems, large: "" },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return _c(
                        "v-breadcrumbs-item",
                        {
                          staticClass: "item",
                          attrs: { exact: "", to: item.to },
                        },
                        [_vm._v(" " + _vm._s(item.text) + " ")]
                      )
                    },
                  },
                  {
                    key: "divider",
                    fn: function () {
                      return [_c("v-icon", [_vm._v("chevron_right")])]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("v-toolbar-title", { staticClass: "pl-2 text-h4" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }