
import { Component, Vue } from 'vue-property-decorator';
import { State } from 'vuex-class';
import moment from 'moment';

import DataModelHeader from '@/components/data-models/DataModelHeader.vue';

import { DATA_TABLE_DETAILS, TABLES_LISTING } from '@/constants/router/routes-names';
import { getBigQueryURL, getFormattedNumBytes, getFormattedNumRows } from '@/util/data-models';

@Component({
	components: { DataModelHeader },
})
export default class TablesListing extends Vue {
	isLoading: boolean = false;
	search: string = '';
	filterOnTimePartitioning: boolean = false;

	@State((state) => state.dataTables.data) tables!: Object;

	activated() {
		this.fetchTables();
	}

	fetchTables() {
		this.$store.dispatch('dataTables/closeDBChannel', { clearModule: true });
		this.isLoading = true;

		return this.$store
			.dispatch('dataTables/fetchAndAdd', {
				projectId: this.projectId,
				datasetId: this.datasetId,
				where: [['account', '==', JSON.parse(localStorage.vuex).filters.filteredAccounts[0].id]],
				limit: 0,
			})
			.then(() => {
				this.isLoading = false;
			});
	}

	getTableViewLink(tableId: string) {
		return {
			name: DATA_TABLE_DETAILS,
			params: { projectId: this.projectId, datasetId: this.datasetId, tableId },
		};
	}

	getTableName(tableId: string) {
		if (!tableId) return 'Error in schema';

		const splitId = tableId.split('.');
		return tableId ? splitId[splitId.length - 1] : 'Error in schema';
	}

	getRefreshedTimestampColor(refreshedTimestamp: string) {
		let color = 'green';

		const now = moment(new Date());
		const duration = moment.duration(now.diff(refreshedTimestamp)).asHours();
		const hoursFromNow = Math.round((duration + Number.EPSILON) * 100) / 100;

		if (hoursFromNow >= 26 && hoursFromNow < 36) color = 'orange';
		else if (hoursFromNow >= 36 && hoursFromNow < 49) color = 'red';
		else if (hoursFromNow >= 36 && hoursFromNow < 170) color = 'purple';
		else if (hoursFromNow >= 170) color = 'black';

		return color;
	}

	openInBigQuery(tableId: string) {
		window.open(getBigQueryURL(this.projectId, this.datasetId, tableId), '_blank');
	}

	getPropertyPercent(key: string, value: number) {
		const maxPropertyValue = Math.max.apply(
			Math,
			Object.values(this.tables).map((table) => (table[key] ? table[key] : '0'))
		);
		const percent = (Number(value) * 100) / maxPropertyValue;

		return Math.round((percent + Number.EPSILON) * 100) / 100;
	}

	getNumRowsFormatted(numRows: string) {
		return getFormattedNumRows(Number(numRows));
	}

	getNumBytesFormatted(numBytes: string) {
		return getFormattedNumBytes(Number(numBytes));
	}

	get headers() {
		return [
			{ text: 'Account', value: 'account' },
			{ text: 'Table Name', value: 'table_id' },
			{ text: 'Table Type', value: 'type' },
			{ text: 'Temporary', value: 'temporary_table' },
			{ text: 'Row Number', value: 'numRows' },
			{ text: 'Table Size', value: 'numBytes' },
			{ text: 'Location', value: 'location' },
			{ text: 'Time Partitioned', value: 'time_partitioned' },
			{ text: 'Last Update', value: 'refreshed_timestamp' },
			{ text: 'Query', value: 'query' },
		];
	}

	get tableItems() {
		return [
			{
				text: this.projectId,
			},
			{
				text: this.datasetId,
				to: {
					name: TABLES_LISTING,
					params: { projectId: this.projectId, datasetId: this.datasetId },
				},
			},
		];
	}

	get items() {
		return this.filterOnTimePartitioning ? this.timePartitioningTables : Object.values(this.tables);
	}

	get timePartitioningTables() {
		return Object.values(this.tables).filter((table) => table.timePartitioning);
	}

	get datasetId() {
		return this.$route.params.datasetId;
	}

	get projectId() {
		return this.$route.params.projectId;
	}
}
